@keyframes transformIdle {
    0% {width: 75%;height: 90%;}
    50% {width: 80%;height: 85%;}
    100% {width: 75%;height: 90%;}
}

.cv_profile_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin-bottom: 3rem;
}
.cv_profile_image{
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0 3rem 1rem 0rem;
}
.cv_profile_image, img{
    position: relative;
}
.cv_profile_image:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fa9f00;
    width: 75%;
    height: 90%;
    border-radius: 0.5rem;
    animation: transformIdle 5s ease-in-out .5s infinite;
}
.cv_profile_head{
    font-size: 15vw;
    text-align: left;
}
.cv_profile_head span{
    display: block;
    line-height: 1;
    text-transform: uppercase;
    width: 100%;
}
.cv_profile_head span.uno{
    font-size: 1em;
    font-weight: 700;
    line-height: 0.8;
}
.cv_profile_head span.dos{
    font-size: 0.9em;
    font-weight: 500;
    line-height: 0.9;
    color: transparent;
    -webkit-text-stroke: 1px #fafafa;
}
.cv_profile_head span.tres{
    font-size: 0.5em;
    font-weight: 400;
}
.cv_profile_head span.cuatro{
    font-size: 0.4em;
    font-weight: 200;
}

.cv_skills_container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.cv_skills_container_left, .cv_skills_container_right{
    width: 100%;
}



@media (min-width: 700px) {
    
    .cv_profile_container{
      flex-direction: row;
    }
    .cv_profile_image{
        margin: 0 1rem 1rem 4rem;
    }
    
    .home_desc{
      padding: 2rem 4rem 2rem 2rem;
      text-align: right;
    }
    .cv_profile_head{
        font-size: 6vw;
        text-align: left;
        margin-bottom: 3rem;
    }

    .cv_skills_container{
        flex-direction: row;
    }
    .cv_skills_container_left, .cv_skills_container_right{
        padding: 0 0.5rem;
    }
    .cv_skills_container_left{
        width: 70%;
    }
    .cv_skills_container_right{
        width: 30%;
    }
    
    
  }