@keyframes moveIdleX {
  0% {transform: translateX(0);}
  50% {transform: translateX(1rem);}
  100% {transform: translateX(0);}
}
@keyframes moveIdleY {
  0% {transform: translateY(0);}
  50% {transform: translateY(1rem);}
  100% {transform: translateY(0);}
}
.col-12{
  width: 100%;
}
.col-11{
  width: 91.66%;
}
.col-10{
  width: 83.33%;
}
.col-9{
  width: 75%;
}
.col-8{
  width: 66.66%;
}
.col-7{
  width: 58.33%;
}
.col-6{
  width: 50%;
}
.col-5{
  width: 41.66%;
}
.col-4{
  width: 33.33%;
}
.col-3{
  width: 25%;
}
.col-2{
  width: 16.66%;
}
.col-1{
  width: 8.33%;
}

.main_container{
  display: block;
  background: #000;
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
}
.box_shadow{
  box-shadow: 0 0 20px -10px rgba(0,0,0,0.5);
}
.tarjeta{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
}

.header_container{
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
}
.header_title{
  text-transform: uppercase;
  font-size: 1.2em;
  flex-grow: 1;
  margin-right: 1em;
}
.menu_container{
  font-size: 1.5em;
  transition: all 0.2s ease-in-out;
}
.menu_container span{
  font-weight: inherit;
}

.contenido_container{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  padding: 2rem;
  font-size: 0.9rem;
  overflow-y: auto;
}
.footer_container{
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: 300;
}
.footer_container span{
  display: block;
  margin-bottom: 0;
}
.footer_container span.footer_add{
  font-size: 0.8em;
}
.show_more_container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.show_more{
  font-weight: 500;
  user-select: none;
  cursor: pointer;
}
.show_more .link_text{
  margin-right: 0.5em;
}
.show_more span:last-child{
  margin-bottom: -0.2em;
}
.modal_container{
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 2rem;
  background: rgba(0,0,0,0.5);
  transition: all 0.2s ease-in-out;
}
.modal_content{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fafafa;
  border-radius: 1rem;
  overflow: hidden;
}
.modal_header{
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.2em;
}
.modal_header h3{
  margin: 0;
  padding: 1rem;
}
.modal_header span{
  padding: 1rem;
  font-size: 1.2em;
  cursor: pointer;
}
.modal_desc{
  width: 100%;
  height: 100%;
  overflow: auto;
}



.home_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
}
.home_image{
  position: relative;
  padding: 0 3rem 0 5rem;
}
.home_image img{
  position: relative;
  filter: drop-shadow(5px -5px 5px rgba(0,0,0,0.1));
}
.home_image::before{
  content: '';
  display: block;
  position: absolute;
  left: 10%;
  bottom: 0;
  background: #fa9f00;
  width: 80%;
  height: 50%;
  border-radius: 0.5rem;
  animation: moveIdleX 5s ease-in-out 0s infinite;
}
.home_image::after{
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 30%;
  background: #313131;
  width: 11rem;
  height: 11rem;
  z-index: -1;
  border-radius: 50%;
  animation: moveIdleY 5s ease-in-out .5s infinite;
}
.home_desc{
  padding: 2rem 0rem;
  text-align: right;
  
}
.home_desc h1{
  font-size: 2.5em;
}
.home_desc h1 span{
  display: block;
  font-size: 0.8em;
  font-weight: 300;
}
.home_desc_container{
  width: fit-content;
  margin: 0 auto;
}


.project_container{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  width: 100%;
  max-width: 1000px;
  height: fit-content;
  --translate_img: 2rem;
  --padding: 1rem;
}
.image_card_container{
  position: relative;
  background: #fafafa;
  color: #313131;
  height: fit-content;
  padding: var(--padding);
  padding-bottom: 0;
  border-radius: 0.5rem;
  margin-top: var(--translate_img);
}
.image_card_content{
  transform: translateY(calc( (var(--translate_img) + var(--padding)) * -1 ));
}
.image_card_content_desc{
  padding: 0 0.5rem;
}
.image_card_content_desc .image_card_title{
  margin-bottom: 0rem;
  line-height: 1;
}
.image_card_content_desc .image_card_subtitle{
  margin-bottom: 0.5rem;
  line-height: 1;
}
.image_card_container .image_card_img{
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.cv_container{
  width: 100%;
  max-width: 1000px;
}
.title_card_container{
  display: block;
  background: #fafafa;
  color: #313131;
  height: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
}
.title_card_head{
  margin-bottom: 1rem;
}
.title_card_title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  line-height: 1;
}
.title_card_title span.icon{
  margin-left: 1rem;
}
.title_card_subtitle{
  line-height: 1;
}


.bar_skill_container{
  max-width: 500px;
  width: 100%;
  padding: 0.5rem 0;
}
.bar_skill_container h3{
  margin-bottom: 0.5rem;
}
.bar_skill_bar_container{
  width: 100%;
  height: 1em;
  border-radius: 0.25rem;
  background: rgba(0,0,0,0.05);
  overflow: hidden;
}
.bar_skill_bar_status{
  height: 100%;
}
.check_status{
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}




@media (min-width: 700px) {
  .col-12-desk{
    width: 100%;
  }
  .col-11-desk{
    width: 91.66%;
  }
  .col-10-desk{
    width: 83.33%;
  }
  .col-9-desk{
    width: 75%;
  }
  .col-8-desk{
    width: 66.66%;
  }
  .col-7-desk{
    width: 58.33%;
  }
  .col-6-desk{
    width: 50%;
  }
  .col-5-desk{
    width: 41.66%;
  }
  .col-4-desk{
    width: 33.33%;
  }
  .col-3-desk{
    width: 25%;
  }
  .col-2-desk{
    width: 16.66%;
  }
  .col-1-desk{
    width: 8.33%;
  }
  .contenido_container{
    font-size: 1rem;
  }
  .home_container{
    flex-direction: row-reverse;
  }
  .home_image{
    position: relative;
    padding: 0 2rem 0 0rem;
    margin: 2rem 0;
  }
  .home_image::before{
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
  }
  .home_image::after{
    right: -20%;
    bottom: 30%;
    width: 15rem;
    height: 15rem;
  }
  .home_desc{
    padding: 2rem 4rem 2rem 2rem;
    text-align: right;
  }

  .project_container{
    grid-template-columns: repeat(3, 1fr);
  }
  
}