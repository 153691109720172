@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a, a:hover, a:focus {
  text-decoration: none;
  color: inherit;
}

.neo/*, .card*/{
  box-shadow: 5px 5px 7px rgba(0,0,0,0.1), -5px -5px 7px rgba(255,255,255,0.7);
  background: #f0f0f0!important;
  color: #313131!important;
  border-radius: 1rem;
  padding: 5px;
  margin: 5px 0;
}
body {
  font-family: 'Barlow', 'Roboto', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
#root{
  height: 100vh;
}
img{
  display: block;
  width: 100%;
  height: auto;
  user-select: none;
  pointer-events: none;
}
h1{
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 2rem;
}
h2{
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 1rem;
}
h3{
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 1rem;
}
p{
  margin-bottom: 0.75em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
